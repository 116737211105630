<template>
  <div class="ad-list">
    <!-- 页头 -->
    <div class="page-header">
      <el-row>
        <el-col :span="22">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>首页</el-breadcrumb-item>
            <el-breadcrumb-item>会员等级</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
        <el-col :span="2" class="add-btn-container">
          <el-button type="text" @click="toCreate">添加</el-button>
        </el-col>
      </el-row>
    </div>
    <el-form :inline="true" class="demo-form-inline">
      <el-form-item>
        <el-input v-model="name" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList">查询</el-button>
      </el-form-item>
    </el-form>
    <!-- 主要内容 -->
    <div class="main">
      <el-table :data="adminList" border="" style="width: 100%">
        <el-table-column prop="gradeno" label="等级编号" align="center" width="95"></el-table-column>
        <el-table-column prop="sorder" width="95" label="序号">
          <template slot-scope="scope">
            <el-input v-model="scope.row.sorder" @change="updatesort(scope.row)"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="等级图片" width="110" align="center">
          <template slot-scope="scope">
            <el-image :src="scope.row.homeimg">
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="等级名称"></el-table-column>
        <el-table-column prop="point" label="满足积分"></el-table-column>
        <el-table-column prop="moneytopoint" label="会员积分比例"></el-table-column>
        <el-table-column prop="userdiscount" label="会员折扣"></el-table-column>
        <el-table-column prop="addtime" label="添加日期"></el-table-column>
        <el-table-column label="操作" width="213" align="center">
          <template slot-scope="scope">

            <el-button size="mini" @click="toEdit(scope.row.id)">编辑</el-button>
            <el-button type="danger" size="mini" @click="onDelete(scope.row.id)">刪除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <div class="pagination">
      <el-pagination background="" layout="prev, pager, next" :page-size="10" :current-page="pageIndex"
        :total="pageTotal" @current-change="onCurrentChange" />
    </div>
  </div>
</template>

<script>
import { getListRequest, delRequest } from "@/api/wx_MemberUserGrade";
export default {
  name: "usergradeList",
  data() {
    return {
      name: "",
      adminList: [],
      pageIndex: 1,
      pageTotal: 0
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      getListRequest({
        name: this.name,
        pageNum: this.pageIndex,
        pageSize: 10
      }).then(res => {
        let data = res.data;
        this.adminList = data.data;
        this.pageTotal = data.count;
      });
    },
    onDelete(id) {
      this.$confirm("确认删除该等级吗？", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          // 调用删除接口
          return delRequest({ id });
        })
        .then(() => {
          this.$message({
            type: "success",
            message: `删除成功！`
          });
          this.getList();
        });
    },

    toCreate() {
      this.$router.push({
        path: '/usergrade/edit',
        query: { id: 0 },
      })
    },

    toEdit(id) {
      this.$router.push({
        path: '/usergrade/edit',
        query: { id: id },
      })
    },


    onCurrentChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getList();
    }
  }
};
</script>

<style lang="less" scoped>

</style>
